var render = function render() {
  var _vm$form, _vm$form$liveroomuser;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-tabs", {
    attrs: {
      "default-active-key": "undefined"
    },
    on: {
      change: function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-tab-pane", {
    key: undefined,
    attrs: {
      tab: "全部"
    }
  }), _c("a-tab-pane", {
    key: 0,
    attrs: {
      tab: "待开始"
    }
  }), _c("a-tab-pane", {
    key: 1,
    attrs: {
      tab: "直播中"
    }
  }), _c("a-tab-pane", {
    key: 2,
    attrs: {
      tab: "已结束"
    }
  })], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "recordid",
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "liveroomuserinfo",
      fn: function fn(liveroomuserinfo) {
        return [_c("span", {
          staticStyle: {
            color: "#3270f5",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.toUserDetail(liveroomuserinfo ? liveroomuserinfo.uid : "");
            }
          }
        }, [_vm._v(_vm._s(liveroomuserinfo ? liveroomuserinfo.username : "") + " ")])];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [_vm._v(" " + _vm._s(_vm.fmtType(type)) + " ")];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [_vm._v(" " + _vm._s(_vm.fmtStatus(status)) + " ")];
      }
    }, {
      key: "starttime",
      fn: function fn(starttime) {
        return [_vm._v(" " + _vm._s(_vm.dateFormat(starttime)) + " ")];
      }
    }, {
      key: "endtime",
      fn: function fn(endtime) {
        return [_vm._v(" " + _vm._s(_vm.dateFormat(endtime)) + " ")];
      }
    }, {
      key: "istop",
      fn: function fn(istop) {
        return [_vm._v(" " + _vm._s(istop ? "是" : "否") + " ")];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("span", [_c("a", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handlePreview(item);
            }
          }
        }, [_vm._v("查看")])]), item.status == 1 ? _c("span", {
          staticStyle: {
            "margin-left": "10px"
          }
        }, [_c("a", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleClose(item);
            }
          }
        }, [_vm._v("强制关闭")])]) : _vm._e()];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "直播间信息",
      width: 700,
      footer: null,
      centered: "",
      "confirm-loading": _vm.confirmLoading
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    attrs: {
      model: _vm.form,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "直播间名称"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入直播间名称"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _vm.form.status == 1 ? _c("a-form-model-item", {
    attrs: {
      label: "Feed流置顶"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.form.istop,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "istop", $$v);
      },
      expression: "form.istop"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 是 ")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 否 ")])], 1)], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: "主播昵称"
    }
  }, [_c("a-input", {
    attrs: {
      value: (_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$liveroomuser = _vm$form.liveroomuserinfo) === null || _vm$form$liveroomuser === void 0 ? void 0 : _vm$form$liveroomuser.username,
      readonly: "",
      disabled: ""
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "总计访问人数"
    }
  }, [_c("a-input", {
    attrs: {
      value: _vm.form.visitcount,
      readonly: "",
      disabled: ""
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "开播时间"
    }
  }, [_c("a-input", {
    attrs: {
      value: _vm.dateFormat(_vm.form.starttime),
      readonly: "",
      disabled: ""
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "关播时间"
    }
  }, [_c("a-input", {
    attrs: {
      value: _vm.dateFormat(_vm.form.endtime),
      readonly: "",
      disabled: ""
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "状态"
    }
  }, [_c("a-input", {
    attrs: {
      value: _vm.fmtStatus(_vm.form.status),
      readonly: "",
      disabled: ""
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    staticStyle: {
      "max-width": "262px"
    },
    attrs: {
      type: "primary",
      block: "",
      loading: _vm.loading2
    },
    on: {
      click: _vm.updateLiveRoomInfo
    }
  }, [_vm._v("提交")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var LiveStreamingApi = {
  // 直播列表
  getLiveRoomList: function getLiveRoomList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/room/open/list/status', params);
  },
  // 强制关闭直播间
  updateLiveRoomStatus: function updateLiveRoomStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/room/stop', params);
  },
  // 礼物配置列表
  getGiftList: function getGiftList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/gift/managment/list', params);
  },
  // 新增/修改礼物
  saveGiftConf: function saveGiftConf() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/gift/managment/save', params);
  },
  // 礼物上下架
  updateGiftStatus: function updateGiftStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/gift/managment/online', params);
  },
  // 删除礼物
  delGift: function delGift() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/gift/managment/delete', params);
  },
  // 礼物明细列表
  getGiftDetailList: function getGiftDetailList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/user/gift/log/managment/list', params);
  },
  // 修改直播间信息
  updateLiveRoom: function updateLiveRoom() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/accountservice/live/room/managment/update', params);
  }
};
export default LiveStreamingApi;
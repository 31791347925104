var searchKeys = [{
  key: "roomid",
  label: "直播间ID",
  placeholder: "直播间ID",
  required: false,
  input: true
}, {
  key: "username",
  label: "主播昵称",
  placeholder: "主播昵称",
  required: false,
  input: true
}, {
  key: "minstarttime",
  label: "开播时间",
  placeholder: '开播时间',
  required: false,
  date: true,
  attrs: {
    showTime: true
  }
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeys2 = [{
  key: "name",
  label: "礼物名称",
  placeholder: "礼物名称",
  required: false,
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeys3 = [{
  key: "date",
  label: "赠送时间",
  required: false,
  dates: true
}, {
  key: "roomid",
  label: "直播间ID",
  placeholder: "直播间ID",
  required: false,
  input: true
}, {
  key: "name",
  label: "礼物名称",
  placeholder: "礼物名称",
  required: false,
  input: true
}, {
  key: "username",
  label: "发送方昵称",
  placeholder: "发送方昵称",
  required: false,
  input: true
}, {
  key: "userid",
  label: "发送方ID",
  placeholder: "发送方ID",
  required: false,
  input: true
}, {
  key: "gainusername",
  label: "接收方昵称",
  placeholder: "接收方昵称",
  required: false,
  input: true
}, {
  key: "gainuserid",
  label: "接收方ID",
  placeholder: "接收方ID",
  required: false,
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var tableColumns = [{
  title: "直播ID",
  dataIndex: "recordid",
  key: "recordid",
  width: 140
}, {
  title: "直播间ID",
  dataIndex: "roomid",
  key: "roomid",
  width: 120
}, {
  title: "主播昵称",
  dataIndex: "liveroomuserinfo",
  key: "liveroomuserinfo",
  width: 140,
  scopedSlots: {
    customRender: "liveroomuserinfo"
  }
}, {
  title: "总计访问人数",
  dataIndex: "visitcount",
  key: "visitcount",
  width: 120,
  scopedSlots: {
    customRender: "visitcount"
  }
}, {
  title: "类型",
  dataIndex: "type",
  key: "type",
  width: 180,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "开播时间",
  dataIndex: "starttime",
  key: "starttime",
  width: 140,
  scopedSlots: {
    customRender: "starttime"
  }
}, {
  title: "关播时间",
  dataIndex: "endtime",
  key: "endtime",
  width: 140,
  scopedSlots: {
    customRender: "endtime"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  width: 80,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "Feed置顶",
  dataIndex: "istop",
  key: "istop",
  width: 100,
  scopedSlots: {
    customRender: "istop"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
var tableColumns2 = [{
  title: "礼物ID",
  dataIndex: "giftId",
  key: "giftId",
  width: 140
}, {
  title: "礼物名称",
  dataIndex: "name",
  key: "name",
  width: 140
}, {
  title: "礼物icon",
  dataIndex: "icon",
  key: "icon",
  scopedSlots: {
    customRender: "icon"
  }
}, {
  title: "是否收费",
  dataIndex: "iscollect",
  key: "iscollect",
  scopedSlots: {
    customRender: "iscollect"
  }
}, {
  title: "排序",
  dataIndex: "sort",
  key: "sort"
}, {
  title: "价格",
  dataIndex: "amount",
  key: "amount"
}, {
  title: "计价货币",
  dataIndex: "brickType",
  key: "brickType",
  scopedSlots: {
    customRender: "brickType"
  }
}, {
  title: "创建时间",
  dataIndex: "createdData",
  key: "createdData",
  scopedSlots: {
    customRender: "createdData"
  }
}, {
  title: "状态",
  dataIndex: "isonline",
  key: "isonline",
  scopedSlots: {
    customRender: "isonline"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
var tableColumns3 = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: 140,
  fixed: 'left'
}, {
  title: "礼物名称",
  dataIndex: "giftname",
  key: "giftname",
  width: 100
}, {
  title: "礼物数量",
  dataIndex: "giftcount",
  key: "giftcount",
  width: 100
}, {
  title: "发送方昵称",
  dataIndex: "username",
  key: "num",
  width: 120
}, {
  title: "发送方ID",
  dataIndex: "createdBy",
  key: "createdBy",
  width: 160
}, {
  title: "接收方ID",
  dataIndex: "gainUserId",
  key: "gainUserId",
  width: 160
}, {
  title: "接收方昵称",
  dataIndex: "gainUserName",
  key: "gainUserName",
  width: 120
}, {
  title: "赠送时间",
  dataIndex: "createdDate",
  key: "createdDate",
  width: 180,
  scopedSlots: {
    customRender: "createdDate"
  }
}, {
  title: "直播间ID",
  dataIndex: "roomid",
  key: "roomid",
  width: 140
}, {
  title: "价值数量",
  dataIndex: "amount",
  key: "amount",
  width: 100
}, {
  title: "价值单位",
  dataIndex: "brickType",
  key: "brickType",
  width: 100
}, {
  title: "平台分成",
  dataIndex: "proportion",
  key: "proportion",
  width: 100,
  fixed: 'right'
}];
export { searchKeys, searchKeys2, searchKeys3, tableColumns, tableColumns2, tableColumns3 };